exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affinity-js": () => import("./../../../src/pages/affinity.js" /* webpackChunkName: "component---src-pages-affinity-js" */),
  "component---src-pages-auto-insurance-js": () => import("./../../../src/pages/auto-insurance.js" /* webpackChunkName: "component---src-pages-auto-insurance-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-auto-insurance-js": () => import("./../../../src/pages/faqs/auto-insurance.js" /* webpackChunkName: "component---src-pages-faqs-auto-insurance-js" */),
  "component---src-pages-faqs-home-insurance-js": () => import("./../../../src/pages/faqs/home-insurance.js" /* webpackChunkName: "component---src-pages-faqs-home-insurance-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-faqs-motorcycle-insurance-js": () => import("./../../../src/pages/faqs/motorcycle-insurance.js" /* webpackChunkName: "component---src-pages-faqs-motorcycle-insurance-js" */),
  "component---src-pages-faqs-pc-optimum-js": () => import("./../../../src/pages/faqs/pc-optimum.js" /* webpackChunkName: "component---src-pages-faqs-pc-optimum-js" */),
  "component---src-pages-faqs-tenant-insurance-js": () => import("./../../../src/pages/faqs/tenant-insurance.js" /* webpackChunkName: "component---src-pages-faqs-tenant-insurance-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-home-insurance-js": () => import("./../../../src/pages/home-insurance.js" /* webpackChunkName: "component---src-pages-home-insurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insiders-js": () => import("./../../../src/pages/insiders.js" /* webpackChunkName: "component---src-pages-insiders-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-life-insurance-policy-docs-js": () => import("./../../../src/pages/legal/life-insurance-policy-docs.js" /* webpackChunkName: "component---src-pages-legal-life-insurance-policy-docs-js" */),
  "component---src-pages-legal-trademark-js": () => import("./../../../src/pages/legal/trademark.js" /* webpackChunkName: "component---src-pages-legal-trademark-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-make-a-claim-js": () => import("./../../../src/pages/make-a-claim.js" /* webpackChunkName: "component---src-pages-make-a-claim-js" */),
  "component---src-pages-media-centre-index-js": () => import("./../../../src/pages/media-centre/index.js" /* webpackChunkName: "component---src-pages-media-centre-index-js" */),
  "component---src-pages-motorcycle-insurance-js": () => import("./../../../src/pages/motorcycle-insurance.js" /* webpackChunkName: "component---src-pages-motorcycle-insurance-js" */),
  "component---src-pages-online-account-info-js": () => import("./../../../src/pages/online-account-info.js" /* webpackChunkName: "component---src-pages-online-account-info-js" */),
  "component---src-pages-pointsforquote-js": () => import("./../../../src/pages/pointsforquote.js" /* webpackChunkName: "component---src-pages-pointsforquote-js" */),
  "component---src-pages-renewal-5-k-js": () => import("./../../../src/pages/renewal/5k.js" /* webpackChunkName: "component---src-pages-renewal-5-k-js" */),
  "component---src-pages-renewal-getaquote-js": () => import("./../../../src/pages/renewal/getaquote.js" /* webpackChunkName: "component---src-pages-renewal-getaquote-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tenant-insurance-js": () => import("./../../../src/pages/tenant-insurance.js" /* webpackChunkName: "component---src-pages-tenant-insurance-js" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/NewsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-regional-page-js": () => import("./../../../src/templates/RegionalPage.js" /* webpackChunkName: "component---src-templates-regional-page-js" */)
}

